.logo {
  height: 50px;
}

.header {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: rgb(255, 190, 166);
}

.footer {
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: rgb(255, 190, 166);
}

.subtitle {
  font-style: italic;
  font-size: x-large;
  color: coral;
}

.classification {
  font-style: italic;
  color: gray;
  font-size: small;
}

.welcome {
  min-height: 100%;
}

html,
body {
  height: 100%;
}

.welcome-bg {
  background-image: url("./welcome-bg.jpg");
  height: 190px;
}

.welcome-message {
  font-size: xx-large;
  color: firebrick;
}

.main-actions {
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:15px;
  margin-bottom:15px;
}
.crm-only{
    background-color:lightskyblue;
}
.read-only{
    background-color:lightgray;
}
.delete-button {
    padding:3px;
    width:80px;
    color: black;
    border:1px solid darkred;
    font-weight:bold;
    background-color:orangered;
}