.notes {
  color: black;
}
.noteRow{
    text-decoration:none;
    cursor:pointer;
}
td, th{
    padding:5px;
}
.notesSection {
    border: 1px solid lightblue;
    display: inline-block;
    float: left;
    padding: 5px;
    width: 100%;
    margin: 5px;
}

.sectionTitle {
    font-weight: bold;
}
.hidden {
    display: none;
    visibility: hidden;
}
