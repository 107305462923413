.client-files {
  color: black;

}
.clientFileRow{
    text-decoration:none;
    cursor:pointer;
}
td, th{
    padding:5px;
}

