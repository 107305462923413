.client-financial-info {
  color: black;
}
.section{
    border:1px solid lightblue;
}

.sectionTitle{
    font-weight:bold;
}
.hidden {
    display: none;
    visibility: hidden;
}
disabled{
    background-color:lightgray;
    color:darkgray;
}
