.clientFile {
    color: black;
}
.section {
    border: 1px solid lightblue;
    display: inline-block;
    float: left;
    padding: 5px;
    min-width: 400px;
    margin: 5px;
}

.sectionTitle{
    font-weight:bold;
}
.clientDetails {
    border: 1px solid lightblue;
    width: 100%;
    margin-left: 5px;
    margin-top:5px;
}
.commandBar {
    clear: both;
    padding: 15px;
    border: 1px solid lightblue;
    background-color: lightblue;
    width: 100%;
}
.clientFileFooter {
    clear: both;
}
.columnLeft {
    width: 50%;
    display: inline-block;
    height: 40px;
    margin:10px;
}
.columnRight{
    width:40%;
    display:inline-block;
    height:40px;
    text-align:right;
}
