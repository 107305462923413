
td, th{
    padding:5px;
}
.sectionTitle {
    font-weight: bold;
}
.hidden {
    display: none;
    visibility: hidden;
}
